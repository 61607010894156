import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Select,
  Form,
  Collapse,
  Card,
  Tabs,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { JsonViewer } from "@textea/json-viewer";
import handleUpdatePromptlet from "../../utils/promptlet/handleUpdatePromptlet";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import saveAsNewPromptlet from "../../utils/promptlet/saveAsNewPromptlet";
import { customTheme } from "../../utils/themes/jsonViewerTheme";

const { Option } = Select;
const { Panel } = Collapse;

const EditPromptletModal = ({
  promptletToEditModal,
  setPromptletToEditModal,
  setClientErrors,
  clientErrors,
}) => {
  const dispatch = useDispatch();
  const { isLoading: updatePromptletLoading } = useSelector(
    (state) => state?.updatePromptlet
  );
  const { isLoading: addPromptletLoading } = useSelector(
    (state) => state?.addPromptlet
  );
  const [variables, setVariables] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    if (promptletToEditModal) {
      form.setFieldsValue(promptletToEditModal);
      setVariables(promptletToEditModal?.variables);
    }
  }, [promptletToEditModal]);

  const handleUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpdatePromptlet(
          { ...promptletToEditModal, ...values },
          setClientErrors,
          dispatch,
          setPromptletToEditModal
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        saveAsNewPromptlet(
          {
            ...promptletToEditModal,
            ...values,
            steps: values?.steps,
            variables,
          },
          setClientErrors,
          dispatch,
          setPromptletToEditModal
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };
  const handleAddStep = (add) => {
    add({
      key: "",
      title: "",
      order: "",
      persona: "",
      task: "",
      input: "",
      format: "",
      active: true,
      aiChecker: "",
      aiCheckerInstructions: "",
      aiCheckerThreshold: 10,
      humanCheckerStyle: "",
      humanCheckerPrompt: "",
      humanCheckerThreshold: 10,
    });
  };

  const handleRemoveStep = (remove, index) => {
    remove(index);
  };

  const exampleVariableJson = {
    variables: [
      {
        name: "variablename1",
        type: "text",
        value: { content: "John Doe" },
      },
      {
        name: "variablename2",
        type: "image",
        value: {
          content: "image/jpeg",
          url: "bit.ly:3VgENUT",
        },
      },
    ],
  };
  const visibilityOptions = ["private", "sharedUnlocked", "sharedLocked"];
  const tabs = ["Steps", "Variables", "Defaults", "Demo Problem"];
  const tabChildren = [
    <div>
      <Form.List name="steps">
        {(fields, { add, remove }) => (
          <>
            <Collapse accordion>
              {fields.map((field, index) => (
                <Panel
                  header={
                    <div style={{ textAlign: "left" }}>
                      {`Step ${index + 1} | ${form.getFieldValue([
                        "steps",
                        index,
                        "key",
                      ])} | ${form.getFieldValue(["steps", index, "title"])}`}
                    </div>
                  }
                  key={field.key}
                >
                  <Card>
                    <Row gutter={16}>
                      {promptletToEditModal?.visibility === "private" && (
                        <Col span={24}>
                          <Button
                            type="text"
                            danger
                            icon={<MinusCircleOutlined />}
                            onClick={() => handleRemoveStep(remove, index)}
                            style={{ float: "right" }}
                          >
                            Remove Step
                          </Button>
                        </Col>
                      )}
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          label="Key"
                          name={[field.name, "key"]}
                          key={[field.key, "key"]}
                          rules={[
                            {
                              required: true,
                              message: "Key is required",
                            },
                          ]}
                        >
                          <Input placeholder="Key" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          label="Title"
                          name={[field.name, "title"]}
                          key={[field.key, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "Title is required",
                            },
                          ]}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          label="Order"
                          name={[field.name, "order"]}
                          key={[field.key, "order"]}
                          rules={[
                            {
                              required: true,
                              message: "Order is required",
                            },
                          ]}
                        >
                          <Input type="number" placeholder="Order" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          label="Persona"
                          name={[field.name, "persona"]}
                          key={[field.key, "persona"]}
                        >
                          <Input placeholder="Persona" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          {...field}
                          label="Task"
                          name={[field.name, "task"]}
                          key={[field.key, "task"]}
                          rules={[
                            {
                              required: true,
                              message: "Task is required",
                            },
                          ]}
                        >
                          <TextArea placeholder="Task" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          {...field}
                          label="Input"
                          name={[field.name, "input"]}
                          key={[field.key, "input"]}
                          rules={[
                            {
                              required: true,
                              message: "Input is required",
                            },
                          ]}
                        >
                          <TextArea placeholder="Input" />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label="Format"
                          name={[field.name, "format"]}
                          key={[field.key, "format"]}
                        >
                          <Input placeholder="Format" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label="Active"
                          name={[field.name, "active"]}
                        >
                          <Select placeholder="Active">
                            <Option value={true}>True</Option>
                            <Option value={false}>False</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          label="AI Checker"
                          name={[field.name, "aiChecker"]}
                        >
                          <Input placeholder="AI Checker" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          label="AI Checker Instructions"
                          name={[field.name, "aiCheckerInstructions"]}
                        >
                          <Input placeholder="AI Checker Instructions" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          label="AI Checker Threshold"
                          name={[field.name, "aiCheckerThreshold"]}
                        >
                          <Input
                            type="number"
                            placeholder="AI Checker Threshold"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          label="Human Checker Style"
                          name={[field.name, "humanCheckerStyle"]}
                        >
                          <Select
                            placeholder="Human Checker Style"
                            defaultValue="never"
                          >
                            <Option value="never">Never</Option>
                            <Option value="always">Always</Option>
                            <Option value="threshold">Threshold</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          label="Human Checker Prompt"
                          name={[field.name, "humanCheckerPrompt"]}
                        >
                          <Input placeholder="Human Checker Prompt" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          label="Human Checker Threshold"
                          name={[field.name, "humanCheckerThreshold"]}
                        >
                          <Input
                            type="number"
                            placeholder="Human Checker Threshold"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Panel>
              ))}
            </Collapse>
            {promptletToEditModal?.visibility === "private" && (
              <Button
                type="dashed"
                onClick={() => handleAddStep(add)}
                className="w-[100%] mb-2 mt-2"
                icon={<PlusOutlined  className="text-green-500 text-md font-bold"/>}
              >
                Add Step
              </Button>
            )}
          </>
        )}
      </Form.List>
      {clientErrors && (
        <div className="">
          {Object.entries(clientErrors).map(([key, error]) => (
            <div key={key} className="">
              {error}
            </div>
          ))}
        </div>
      )}
    </div>,
    <div className="flex flex-col md:flex-row gap-2 justify-between items-stretch py-4">
      <div className="w-full md:w-[48%]">
        <Form.Item
          className="text-md font-bold py-2"
          label="JSON Variable String"
        >
          <TextArea
            value={promptletToEditModal.variables}
            onChange={(e) =>
              setPromptletToEditModal((promptletToEditModal) => ({
                ...promptletToEditModal,
                variables: e.target.value,
              }))
            }
            placeholder={`Enter variables JSON String`}
            rows={19}
          />
        </Form.Item>
      </div>
      <div className="w-full md:w-[48%] ">
        <Form.Item label="Example" className="text-md font-bold py-2">
          <JsonViewer
            value={exampleVariableJson}
            theme={customTheme}
            collapsed={false}
            displayDataTypes={false}
            displaySize={false}
            enableClipboard={true}
            rootName={false}
            className="h-full md:h-[425px] text-left"

          />
        </Form.Item>
      </div>
    </div>,
    <div>
      <Form.Item label="Style Information" className="text-md font-bold py-2">
        <TextArea
          value={promptletToEditModal.styleInformation}
          onChange={(e) =>
            setPromptletToEditModal((promptletToEditModal) => ({
              ...promptletToEditModal,
              styleInformation: e.target.value,
            }))
          }
          placeholder={`Enter Style Informations`}
          rows={12}
        />
      </Form.Item>
    </div>,
    <div >
      <Form.Item label="Demo Problem" className="text-md font-bold py-2">
        <TextArea
          value={promptletToEditModal.demoProblem}
          onChange={(e) =>
            setPromptletToEditModal((promptletToEditModal) => ({
              ...promptletToEditModal,
              demoProblem: e.target.value,
            }))
          }
          placeholder={`Enter Demo Problem`}
          rows={12}
        />
      </Form.Item>
    </div>,
  ];
  return (
    <Modal
      centered
      open={promptletToEditModal?.open}
      onCancel={() =>
        setPromptletToEditModal({
          open: false,
          copy: false,
          id: "",
          title: "",
          steps: [],
          visibility: "",
          variables: "",
        })
      }
      footer={[
        <Button
          key={promptletToEditModal.copy ? "Save" : "Update"}
          className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"

          onClick={promptletToEditModal.copy ? handleSave : handleUpdate}
          loading={addPromptletLoading || updatePromptletLoading}
        >
          {promptletToEditModal.copy
            ? addPromptletLoading
              ? "Saving..."
              : "Save"
            : updatePromptletLoading
            ? "Updating..."
            : "Update"}
        </Button>,
      ]}
      width={900}
      bodyStyle={{ minHeight: 600, overflowY: "auto" }}
      title="Edit Promptlet"
    >
      <div className="">
        <Form
          form={form}
          initialValues={promptletToEditModal}
          layout="vertical"
        >
          <div className="flex flex-col md:flex-row gap-2">
            <div className="w-full md:w-[48%]">
              <Form.Item
                label="Promptlet Title"
                name="title"
                className="text-md font-bold"
                rules={[{ required: true, message: "Please enter a title" }]}
              >
                <Input placeholder="Promptlet Title" size="large" />
              </Form.Item>
            </div>
            <div className="w-full md:w-[48%]">
              <Form.Item label="Visibility" name="visibility" className="text-md font-bold">
                <Select className="h-[40px]">
                  {visibilityOptions.map((option, index) => (
                    <Option key={index} value={option}>
                      {option === "private" && <p>Private</p>}
                      {option === "sharedUnlocked" && <p>Shared (Unlocked)</p>}
                      {option === "sharedLocked" && <p>Shared (Locked)</p>}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Tabs
            type="card"
            items={tabs?.map((tabName, index) => {
              const id = String(index + 1);
              return {
                label: tabName,
                key: id,
                children: tabChildren[index],
              };
            })}
          />
          {clientErrors?.title && (
            <span className="">{clientErrors?.title}</span>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default EditPromptletModal;
