import { Modal, Input, Button } from "antd";
import ReactMarkdown from "react-markdown";
import handleDownload from "../../utils/problemConversation/Document/compileToDocument";
import { useDispatch, useSelector } from "react-redux";
const EditorModal = ({
  isEditorModalVisible,
  handleUpdateResponse,
  handleCancelEditorModal,
  editedResponse,
  setEditedResponse,
  isCompiling,
  compiledChats,
  setCompiledChats,
}) => {
  const dispatch = useDispatch();
  const { isLoading: loading } = useSelector(
    (state) => state.compileChatsToDocument
  );
  let content = isCompiling ? compiledChats : editedResponse;
  const codeRegex = /```([\s\S]*?)```/g;
  let match;
  let lastIndex = 0;
  let processedMarkdown = "";

  while ((match = codeRegex.exec(content)) !== null) {
    const codeBlock = match[0];

    processedMarkdown += content
      .slice(lastIndex, match.index)
      .replace(/\n/g, "\n&nbsp;");
    processedMarkdown += codeBlock;
    lastIndex = match.index + codeBlock.length;
  }

  processedMarkdown += content.slice(lastIndex).replace(/\n/g, "\n&nbsp;");

  return (
    <Modal
      title={isCompiling ? "Preview Compiled Chats" : "Edit Chat Response"}
      open={isEditorModalVisible}
      onOk={handleUpdateResponse}
      onCancel={handleCancelEditorModal}
      okText="Save"
      cancelText="Cancel"
      width={1200}
      footer={[
        isCompiling && (
          <Button
            key="download"
            disabled={loading}
            loading={loading}
            onClick={() => handleDownload(compiledChats, dispatch)}
            value="download"
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Download
          </Button>
        ),
        <Button
          key="cancel"
          className="hover:!text-red-500 hover:!border-red-500"
          onClick={handleCancelEditorModal}
        >
          Cancel
        </Button>,
        !isCompiling && (
          <Button
            key="save"
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
            onClick={handleUpdateResponse}
          >
            Save
          </Button>
        ),
      ]}
    >
      <div className="flex flex-col md:flex-row gap-6 mt-4 w-[100%]">
        <Input.TextArea
          value={content}
          onChange={
            isCompiling
              ? (event) => setCompiledChats(event.target.value)
              : (event) => setEditedResponse(event.target.value)
          }
          style={{ height: "80vh" }}
          className="md:w-[50%] w-full rounded-xl p-5 text-lg"
        />
        <div className="md:w-[50%] h-[80vh] w-full bg-black text-white rounded-xl p-5 overflow-auto text-lg">
          <ReactMarkdown className="whitespace-pre-wrap">
            {processedMarkdown}
          </ReactMarkdown>
        </div>
      </div>
    </Modal>
  );
};

export default EditorModal;
