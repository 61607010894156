import { Button } from "antd";
import { formatDateToStandard } from "../../utils/helper";
import { useSelector } from "react-redux";

const DataSource = (users, currentLoggedInUser, handleDeleteUser) => {
  const { isLoading } = useSelector((state) => state.deleteUser);
  return users?.map((user, i) => {
    return {
      key: i,
      date: <p className="">{formatDateToStandard(user?.created_at)}</p>,
      name: <p className="">{user?.name}</p>,
      email: <p className="">{user?.email}</p>,
      debugLevel: <p className="">{user?.debugLevel}</p>,
      action: (
        <div className="">
          {user?.email !== currentLoggedInUser?.email && (
            <Button
              style={{ color: "red" }}
              disabled={isLoading}
              loading={isLoading}
              onClick={() => handleDeleteUser(user?._id)}
            >
              Delete
            </Button>
          )}
        </div>
      ),
    };
  });
};

export default DataSource;
