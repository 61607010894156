import { addChat } from "../../../Redux/Features/Chat/addChatSlice";
import { toastError } from "../../toast";
const addChatUtility = (data,dispatch,setIsTyping,navigate,problemData,problemId,message,humanCheckerAnswer)=>{
    dispatch(
        addChat({
          id: problemId,
          role: "user",
          content: problemData?.description || data?.description,
          humanCheckerAnswer,
          additionalInformation: message,
          debugLevel: 0,
          action: message ? "reject" : "Initial Query",
  
          onSuccess: (data) => {
            setIsTyping(false);
            if (data?.status === "STEPS_COMPLETED") {
              navigate("/");
            }
          },
          onError: (data) => {
            setIsTyping(false);
            toastError(data?.message)

          },
        })
      );
}

export default addChatUtility;