import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";
import setAuthToken from "../../../utils/axios/setAuthToken";
import axios from "axios";
import { BASE_URL } from "../../../Config";

export const addLog = createAsyncThunk(
  "Add Log",
  async (
    {
      stepId,
      libraryId,
      debugLevel,
      action,
      duration,
      input,
      output,
      error,
    },
    { rejectWithValue }
  ) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const conversationId = localStorage.getItem("conversationId");
      const response = await axios.post(`${BASE_URL}/api/user/logs/`, {
        conversationId,
        stepId,
        libraryId,
        debugLevel,
        action,
        duration,
        input,
        output,
        error,
      });
      return response?.data;
    } catch (error) {
      axiosErrorHandler(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
};
const addLogSlice = createSlice({
  name: "Add Log",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addLog.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(addLog.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action?.payload?.data;
    });
    builder.addCase(addLog.rejected, (state,action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.message = action?.payload?.data;
    });
  },
});


export default addLogSlice?.reducer;