import React from "react";
import {
  Modal,
  Button,
  Descriptions,
  Collapse,
  Card,
  Tabs,
  Input,
} from "antd";
import { JsonViewer } from "@textea/json-viewer";
import { customTheme } from "../../utils/themes/jsonViewerTheme";

const { Panel } = Collapse;

const ViewPromptletModal = ({ viewPromptletModal, setViewPromptletModal }) => {
  const tabs = ["Steps", "Variables"];
  const tabsChildren = [
    <div className="">
      <Collapse>
        {viewPromptletModal?.steps?.map((step, index) => (
          <Panel
            header={`Step ${index + 1} | ${step.key} | ${step?.title}`}
            key={index}
          >
            <Card>
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Key">{step.key}</Descriptions.Item>
                <Descriptions.Item label="Title">
                  {step.title}
                </Descriptions.Item>
                <Descriptions.Item label="Order">
                  {step.order}
                </Descriptions.Item>
                <Descriptions.Item label="Persona">
                  {step.persona}
                </Descriptions.Item>
                <Descriptions.Item label="Task">{step.task}</Descriptions.Item>
                <Descriptions.Item label="Input">
                  {step.input}
                </Descriptions.Item>
                <Descriptions.Item label="Format">
                  {step.format}
                </Descriptions.Item>
                <Descriptions.Item label="Active">
                  {step.active ? "True" : "False"}
                </Descriptions.Item>
                <Descriptions.Item label="AI Checker">
                  {step.aiChecker}
                </Descriptions.Item>
                <Descriptions.Item label="AI Checker Instructions">
                  {step.aiCheckerInstructions}
                </Descriptions.Item>
                <Descriptions.Item label="AI Checker Threshold">
                  {step.aiCheckerThreshold}
                </Descriptions.Item>
                <Descriptions.Item label="Human Checker Style">
                  {step.humanCheckerStyle}
                </Descriptions.Item>
                <Descriptions.Item label="Human Checker Prompt">
                  {step.humanCheckerPrompt}
                </Descriptions.Item>
                <Descriptions.Item label="Human Checker Threshold">
                  {step.humanCheckerThreshold}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Panel>
        ))}
      </Collapse>
    </div>,
    <div className="flex flex-col gap-2">
      <p className="text-md font-bold">Variables</p>
      <JsonViewer
        value={
          viewPromptletModal?.variables &&
          JSON.parse(viewPromptletModal?.variables)?.variables
        }
        theme={customTheme}
        collapsed={false}
        displayDataTypes={false}
        displaySize={false}
        enableClipboard={true}
        rootName={false}
        className="h-full md:h-[425px] text-left"
      />
    </div>,
  ];
  return (
    <Modal
      centered
      open={viewPromptletModal?.open}
      title="View Promptlet"
      onCancel={() =>
        setViewPromptletModal({
          open: false,
          id: "",
          title: "",
          steps: [],
          visibility: "",
          variables: "",
        })
      }
      footer={[
        <Button
          key="close"
          type="primary"
          onClick={() => setViewPromptletModal({ open: false })}
        >
          Close
        </Button>,
      ]}
      width={900}
      bodyStyle={{ minHeight: 600, overflowY: "auto" }}
    >
      <div className="flex flex-col gap-6">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-2 w-full">
            <p className="text-md font-bold"> Promptlet Title</p>
            <Input value={viewPromptletModal?.title} disabled />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <p className="text-md font-bold">Promptlet Visibility</p>
            <Input value={viewPromptletModal.visibility} disabled />
          </div>
        </div>
        <div>
          <Tabs
            type="card"
            items={tabs.map((tabName, index) => {
              const id = String(index + 1);
              return {
                label: tabName,
                key: id,
                children: tabsChildren[index],
              };
            })}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ViewPromptletModal;
