import React from "react";
import { Modal, Button, Skeleton } from "antd";
import { JsonViewer } from "@textea/json-viewer";
import { useSelector } from "react-redux";
import { customTheme } from "../../utils/themes/jsonViewerTheme";

const DebugViewerModal = ({ open, onCancel }) => {
  const { data: currentStepLogs, isLoading: isLogLoading } = useSelector(
    (state) => state.getAllLogsOfStepInSpecificConversation
  );

  return (
    <Modal
      title="Debug"
      open={open}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancel"
          onClick={onCancel}
          className="hover:!text-red-500 hover:!border-red-500"
        >
          Cancel
        </Button>,
      ]}
      width={800}
    >
      <div className="bg-black">
        {isLogLoading ? (
          <div className="flex flex-col">
            <Skeleton className="bg-white" />
          </div>
        ) : (
          <>
            {currentStepLogs && Object.keys(currentStepLogs).length > 0 ? (
              <JsonViewer
                value={currentStepLogs}
                theme={customTheme}
                collapsed={false}
                displayDataTypes={false}
                displaySize={false}
                enableClipboard={true}
                className="whitespace-pre-wrap"
                rootName={false}
              />
            ) : (
              <div className="text-center p-6 border-2 border-[#ddd] rounded-md">
                No Logs found
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default DebugViewerModal;
