import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../utils/helper";
import { Button, Drawer } from "antd";
import { userLogout } from "../Redux/Features/User/userLogoutSlice";
import { MenuOutlined } from "@ant-design/icons";
import { toastSuccess } from "../utils/toast";
import { getPromptlet } from "../Redux/Features/Promptlet/getPromptletSlice";
import EditPromptletModal from "../components/Promptlets/EditPromptletModal";
import { AiOutlineLogout } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import ProfileAvator from "../components/Profile/ProfileAvator";
import NavMenu from "../components/Common/NavMenu";
import ProfileSetting from "../components/Profile/ProfileSetting";
const BasicLayout = ({
  children,
  isPadding = true,
  setIsMergeDocumentModalVisible,
  problemData,
  isMenu = true,
  handleCompileChats,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showProfile, setshowProfile] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isMdOrAbove, setIsMdOrAbove] = useState(window.innerWidth >= 768);
  const [isSMOrBellow, setIsSMOrBellow] = useState(window.innerWidth < 768);
  const [selectedTab, setSelectedTab] = useState([]);
  const user = useSelector((state) => state.getUser.user);
  const { isLoading: updatePromptletLoading } = useSelector(
    (state) => state?.updatePromptlet
  );
  const isOwner = problemData?.library?.user === user?._id;
  const [promptletToEditModal, setPromptletToEditModal] = useState({
    open: false,
    id: "",
    title: "",
    steps: [],
    visibility: "",
    variables: "",
    styleInformation: "",
    demoProblem: "",
  });
  let [clientErrors, setClientErrors] = useState({});
  const handleLogout = () => {
    dispatch(
      userLogout({
        onSuccess: () => {
          toastSuccess("User Logged out successfuly");
          sessionStorage.removeItem("tokenExpiration");
          removeCookie("token");
          navigate(`/`);
        },
        onError: () => {},
      })
    );
  };

  useEffect(() => {
    let pathName = `/${window.location?.pathname?.split("/")[1]}`;
    if (pathName === "/home" || pathName === "/chat") {
      setSelectedTab((prev) => {
        return ["1"];
      });
    } else if (pathName === "/threads") {
      setSelectedTab((prev) => {
        return ["2"];
      });
    } else if (pathName === "/promptlets") {
      setSelectedTab(["3"]);
    } else if (pathName === "/admin-only-threads") {
      setSelectedTab(["4"]);
    } else if (pathName === "/users") {
      setSelectedTab(["5"]);
    } else if (pathName === "/about") {
      setSelectedTab(["6"]);
    } else if (pathName === "/help") {
      setSelectedTab(["7"]);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMdOrAbove(window.innerWidth >= 768);
      setIsSMOrBellow(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };
  const handlePromptletNameClick = (id) => {
    dispatch(
      getPromptlet({
        id,
        onSuccess: (promptlet) => {
          setPromptletToEditModal((prevState) => ({
            ...prevState,
            open: true,
            id: promptlet._id,
            steps: promptlet.steps,
            title: promptlet.name,
            visibility: promptlet.visibility,
            variables: promptlet.variables,
            styleInformation: promptlet?.styleInformation,
            demoProblem: promptlet?.demoProblem,
          }));
        },
      })
    );
  };
  return (
    <div className="flex bg-white h-screen flex-col">
      {isMenu && (
        <div className="flex justify-between gap-6 items-stretch border-b-4 bg-white sticky top-0 z-10 ">
          {isMdOrAbove && (
            <div className="flex items-center pl-4">
              <p
                className="text-4xl font-extrabold text-blue-600 uppercase cursor-pointer special-font"
                onClick={() => navigate("/")}
              >
                <b>A</b>U<b>T</b>oB<b>A</b>
              </p>
            </div>
          )}
          <div className="md:w-[65%] ">
            {isSMOrBellow && (
              <MenuOutlined
                className="font-bold text-xl text-[#1890ff] px-2 py-9"
                onClick={showDrawer}
              />
            )}
            {isMdOrAbove && (
              <NavMenu
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                user={user}
                mode="horizontal"
              />
            )}
          </div>
          {problemData && !isPadding && (
            <div className="flex flex-col md:flex-row gap-2 items-center">
              <p
                className="text-md text-[#1890ff] font-bold truncate overflow-hidden max-w-xs md:max-w-sm"
                title={problemData?.title}
              >
                <span>
                  {problemData?.title?.length > 10
                    ? `${problemData?.title?.slice(0, 10)}...`
                    : problemData?.title}
                </span>
              </p>
              <span className="text-lg font-bold text-pink-500">::</span>
              <p
                className={`text-[#1890ff] text-md font-bold truncate overflow-hidden max-w-xs md:max-w-sm ${
                  isOwner ? "cursor-pointer underline" : ""
                }`}
                title={problemData?.library?.name}
                onClick={
                  isOwner
                    ? () => handlePromptletNameClick(problemData?.library?._id)
                    : undefined
                }
              >
                <span>
                  {problemData?.library?.name?.length > 10
                    ? `${problemData?.library?.name?.slice(0, 10)}...`
                    : problemData?.library?.name}
                </span>
              </p>
            </div>
          )}

          <div className="flex gap-5 me-4  items-center justify-center ">
            {user?.picture ? (
              <ProfileAvator
                url={user.picture}
                setshowProfile={setshowProfile}
              />
            ) : (
              <FaUserCircle
                fontSize={36}
                className="cursor-pointer text-blue-600"
                onClick={() => setshowProfile(true)}
              />
            )}
            <AiOutlineLogout
              fontSize={21}
              className="text-red-500 cursor-pointer"
              onClick={handleLogout}
              mode="vertical"
            />
          </div>
        </div>
      )}
      <div className="mb-auto">

      {children}
      </div>
      {!isPadding && (
        <div className="flex sticky p-4 gap-2 mt-2">
          <Button
            type="dashed"
            onClick={handleCompileChats}
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Compile to Document
          </Button>
          <Button
            type="dashed"
            onClick={() => setIsMergeDocumentModalVisible(true)}
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          >
            Merge Document
          </Button>
        </div>
      )}

      <Drawer placement="left" onClose={closeDrawer} visible={isDrawerVisible}>
        <div className="flex flex-col gap-4">
          <div className="flex items-center pl-4 ">
            <p
              className="text-2xl font-bold text-blue-700 uppercase special-font cursor-pointer"
              onClick={() => navigate("/")}
            >
              <b>A</b>U<b>T</b>oB<b>A</b>
            </p>
          </div>
          <NavMenu
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            user={user}
          />
        </div>
      </Drawer>
      {isOwner && (
        <EditPromptletModal
          promptletToEditModal={promptletToEditModal}
          setPromptletToEditModal={setPromptletToEditModal}
          updatePromptletLoading={updatePromptletLoading}
          setClientErrors={setClientErrors}
          clientErrors={clientErrors}
        />
      )}
      {showProfile && (
        <ProfileSetting
          user={user}
          showProfile={showProfile}
          setshowProfile={setshowProfile}
        />
      )}
      <div className="flex flex-col">
        <div className="flex bg-gray-900 h-40 items-center justify-between px-4 bg-gradient-to-t from-gray-900 to-gray-700">
          <div className="">
            <p
              className="text-4xl font-bold text-blue-50 cursor-pointer uppercase special-font"
              onClick={() => navigate("/")}
            >
              <b>A</b>U<b>T</b>oB<b>A</b>
            </p>
          </div>
          <div>
            <ul className="list-none flex flex-col gap-2">
              <li className="">
                <a
                  href="https://platform.openai.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white font-bold no-underline hover:underline hover:text-blue-400"
                >
                  OpenAi
                </a>
              </li>
              <li className="">
                <a
                  href="https://plantuml.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white font-bold no-underline hover:underline hover:text-blue-400"
                >
                  Plant UML
                </a>
              </li>
              <li className="">
                <a
                  href="https://bpmn.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white font-bold hover:underline hover:text-blue-400 no-underline"
                >
                  BPMN.IO
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-auto py-4 bg-gray-100">
          <p className="text-center text-blue-700 font-bold">
            2024 &copy; All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasicLayout;
