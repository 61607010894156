import { addLog } from "../../../Redux/Features/Log/addLogSlice";

const handleEditResponse = async (
  chatItem,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  chats,
  dispatch
) => {
  const lastChatIndex = chats.length - 1;
  const currentStep = chats[lastChatIndex]?.data?.step;
  const startTime = Date.now();
  let error = "";
  if (currentStep && chats[lastChatIndex]?.data[currentStep.key]) {
    setEditedResponse(
      chats[lastChatIndex]?.data[currentStep.key]?.content || ""
    );
    setIsEditorModalVisible(true);
  } else {
    error = "Error: Unable to find the current step or the last chat data.";
  }
  const endTime = Date.now();

  const { _id, stepId } = chats[chats.length - 1]?.data?.library;

  dispatch(
    addLog({
      stepId,
      libraryId: _id,
      debugLevel: 1,
      action: "Edit",
      duration: endTime - startTime,
      input: chatItem,
      output: editedResponse,
      error: error,
    })
  );
};

export default handleEditResponse;
