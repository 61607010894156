import { Form, Input, Modal } from "antd";
import React from "react";
import { FaUserCircle, FaEnvelope, FaKey, FaUserTag } from "react-icons/fa";

const ProfileSetting = ({ showProfile, setshowProfile, user }) => {
  return (
    <Modal open={showProfile} onCancel={() => setshowProfile(false)}>
      <div className="w-full h-screen bg-white mb-10">
        <div className="flex flex-col gap-4">
          <div className="flex justify-center mb-4">
            {user.picture ? (
              <img
                src={user.picture}
                alt="User"
                className="rounded-full w-20 h-20"
              />
            ) : (
              <FaUserCircle fontSize={80} className="text-blue-600" />
            )}
          </div>

          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <Form.Item label="Name" className="text-md font-bold" />
              <div className="flex items-center gap-2">
                <FaUserCircle className="text-gray-500" />
                <Input value={user.name} />
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <Form.Item label="Email" className="text-md font-bold" />
              <div className="flex items-center gap-2">
                <FaEnvelope className="text-gray-500" />
                <Input value={user.email} />
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <Form.Item label="Invitation Code" className="text-md font-bold" />
              <div className="flex items-center gap-2">
                <FaKey className="text-gray-500" />
                <Input type="number" value={user.invitationCode} />
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <Form.Item label="User Type" className="text-md font-bold" />
              <div className="flex items-center gap-2">
                <FaUserTag className="text-gray-500" />
                <Input type="text" value={user.userType} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileSetting;
