import React, { useEffect, useState } from "react";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { getAllThreads } from "../../Redux/Features/Thread/getAllThreads";
import DataSource from "./Common/DataSource";
import columns from "./Common/Columns";
import expandedRowRender from "./Common/ExpandedRowRender";
const AddminOnlyThreads = () => {
  const dispatch = useDispatch();

  const { data: getAllThreadsData, isLoading: getAllThreadsLoading } =
    useSelector((state) => state?.getAllThreads);
  const [problemsData, setProblemsData] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState([]);

  useEffect(() => {
    dispatch(getAllThreads());
  }, []);

  useEffect(() => {
    if (getAllThreadsData?.length > 0) {
      const modifiedThreadsData = getAllThreadsData?.map((thread) => {
        const response = thread?.response?.map((res, i) => {
          const key = Object?.keys(res)?.find((key) => key != "stepId");
          return {
            key: i,
            name: key,
            title: res[key]?.title,
            content: res[key]?.content,
          };
        });
        return {
          ...thread,
          response: response,
          problem: thread?.problem,
        };
      });
      setProblemsData(modifiedThreadsData);
    }
  }, [getAllThreadsData]);

  return (
    <BasicLayout>
      <div className="">
        <div className="mx-4 mt-4 rounded-lg shadow-sm">
          <Table
            loading={getAllThreadsLoading}
            dataSource={DataSource(problemsData)}
            columns={columns}
            expandable={{
              expandedRowRender: (record) =>
                expandedRowRender(record, problemsData),
              onExpand: (data) => {
                setSelectedProblem((prev) => {
                  return [...prev, data?.key?.toString()];
                });
              },
              defaultExpandedRowKeys: ["0"],
            }}
          />
        </div>
      </div>
    </BasicLayout>
  );
};

export default AddminOnlyThreads;
