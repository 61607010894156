import { Fragment, useEffect, useState } from "react";
import { getCookie } from "../utils/helper";
import setAuthToken from "../utils/axios/setAuthToken";
import { v4 as uuidv4 } from "uuid";
import processContent from "../utils/renderContent/processContent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const RenderContent = ({
  problemId,
  componentKey,
  content,
  title,
  chats,
  language,
  isWritting,
  setIsWritting,
  isResume,
  setChats,
}) => {
  const [image, setImage] = useState(null);
  const [plantUMLPresent, setPlantUMLPResent] = useState(false);
  useEffect(() => {
    const token = getCookie("token");
    setAuthToken(token);
    processContent(
      problemId,
      content,
      chats,
      setPlantUMLPResent,
      setIsWritting,
      setImage,
      language,
      isResume,
      setChats
    );
  }, []);

  if (!content?.length) return;
  // replace open/close tags with HTML entities
  let msgHTML = content
    .replace(/[\u200B-\u200D\uFEFF]/g, "")
    .replace(/\</g, "&lt")
    .replace(/\>/g, "&gt")
    .replace(
      /\`\`\`(.*?)[\n\r]+([^\`]*)\`\`\`/g,
      '<div class="code"><div class="code-language">$1</div><div class="code-options"></div><div class="code-block">$2</div></div>'
    );

  msgHTML = msgHTML.replace(
    /(<div class="code-language">plantuml<\/div><div class=\"code-options\">)(<\/div>)(<div class="code-block">)([^\<\>]+)(?=<\/div><\/div>)/g,
    (m0, m1, m2, m3, m4) => {
      m4.replace(/&lt/g, "<").replace(/&gt/g, ">");

      return `${m1}<button class="seeCode" onclick="switchImage(this);">See code</button><button class="seeImage" onclick="switchImage(this);">See image</button>${m2}<div class="code-img"><img crossOrigin="Anonymous" src="${image}" alt="PlantUML Diagram"></div>${m3}${m4}`;
    }
  );

  msgHTML = msgHTML.replace(
    /(?<=<div class="code-options">)/g,
    `${
      componentKey ? `{%${componentKey.toUpperCase()}}` : ""
    }<button onclick="copyCode(this);">Copy</button>`
  );

  msgHTML = msgHTML
    .replace(
      /(<div class="code-block">)([^<>]+)(<\/div>)/g,
      (m0, m1, m2, m3) => {
        return (
          m1 +
          m2
            .replace(/\*/g, "&#42;")
            .replace(/_/g, "&#95;")
            .replace(/\[/g, "&#91;")
            .replace(/\]/g, "&#93;")
            .replace(/`/g, "&#96;") +
          m3
        );
      }
    )
    .replace(/(?<!&#8203;)([\`]+([^\`]+)[\`]+)/g, "<b>$1</b>")
    .replace(/(?<!&#8203;)([\*]+([^\*]+)[\*]+)/g, "<b>$2</b>")
    .replace(/(?<!&#8203;)([\_]+([^\_]+)[\_]+)/g, "<i>$2</i>")
    .replace(
      /(?<=^|\n|\r)(#+ ?)([^<>\n\r]+)(?=[\n\r])/g,
      (m0, m1, m2) => "<h2>" + m2.trim() + "</h2>"
    )
    .replace(/(?<!&#8203;)(\[([^\[\]]+)\])/g, "<h2>$2</h2>")
    .replace(/[\n\r]+/g, "<br />");

  return (
    <Fragment key={uuidv4()}>
      {(plantUMLPresent && !image) || isWritting ? (
        <div>
          <Skeleton height={100} />
          <Skeleton count={3} />
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 items-center">
            <p className="text-md font-sans font-bold text-black uppercase">
          {title}
            </p>
            <div className="h-1 w-5 bg-pink-500"/>
            <p className="text-md font-sans font-bold text-blue-600 uppercase">
            {`{step_${componentKey}}`}
            </p>
          </div>
            <div
              className="msgContent text-black font-sans text-md"
              dangerouslySetInnerHTML={{ __html: msgHTML }}
            />
        </div>
      )}
    </Fragment>
  );
};

export default RenderContent;
