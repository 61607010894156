import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Tabs,
  Typography,
} from "antd";
import handleUpdateProblem from "../../utils/home/handleUpdateProblem";
import handleStepsData from "../../utils/home/handleStepsData";
import { useDispatch } from "react-redux";
import { JsonViewer } from "@textea/json-viewer";
import { customTheme } from "../../utils/themes/jsonViewerTheme";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const EditProblemModal = ({
  editProblemModal,
  setEditProblemModal,
  updateProblemLoading,
  setClientErrors,
  clientErrors,
  promptlets,
}) => {
  const dispatch = useDispatch();
  const [toggleNext, settoggleNext] = useState(false);
  const supportedLanguages = [
    "English",
    "Spanish",
    "French",
    "German",
    "Portuguese",
    "Italian",
    "Dutch",
    "Russian",
    "Chinese (Simplified)",
    "Chinese (Traditional)",
    "Japanese",
    "Korean",
    "Arabic",
    "Hindi",
    "Bengali",
    "Urdu",
    "Persian/Farsi",
    "Turkish",
    "Polish",
    "Swedish",
    "Danish",
    "Norwegian",
    "Finnish",
    "Greek",
    "Thai",
    "Hebrew",
    "Indonesian",
    "Vietnamese",
    "Malay",
    "Romanian",
    "Czech",
    "Hungarian",
    "Slovak",
    "Ukrainian",
    "Bulgarian",
    "Serbian",
    "Croatian",
  ];
  const handleCancel = () => {
    setEditProblemModal({
      open: false,
      id: "",
      title: "",
      description: "",
      library: "",
      language: "",
      variables: "",
      styleInformation: "",
      demoProblem: "",
    });
    setClientErrors({});
  };

  const handleOk = () => {
    handleUpdateProblem(
      editProblemModal,
      setClientErrors,
      dispatch,
      setEditProblemModal
    );
  };

  const stepsData = handleStepsData(null, promptlets);

  const tabs = ["Problem Detail", "Variables", "Defaults", "Demo Problem"];
  const exampleVariableJson = {
    variables: [
      {
        name: "variablename1",
        type: "text",
        value: { content: "John Doe" },
      },
      {
        name: "variablename2",
        type: "image",
        value: {
          content: "image/jpeg",
          url: "bit.ly:3VgENUT",
        },
      },
    ],
  };
  const tabChildren = [
    <div className="py-2 ">
      <Form layout="vertical">
        <Form.Item
          label="Problem Title"
          className="text-md font-bold"
          validateStatus={clientErrors?.title ? "error" : ""}
          help={clientErrors?.title}
        >
          <Input
            placeholder="Problem Title"
            size="large"
            value={editProblemModal?.title}
            onChange={(e) =>
              setEditProblemModal({
                ...editProblemModal,
                title: e.target.value,
              })
            }
          />
        </Form.Item>
        <div className="flex flex-col gap-0 py-6">
          <div className="flex justify-between items-center py-2">
            <div>
              <p className="text-md font-bold">Problem Description</p>
            </div>
            <div>
              {!toggleNext && (
                <Button
                  className="!bg-gradient-to-r !from-blue-500 !to-blue-600 hover:!from-blue-600 hover:!to-blue-700 !text-white font-bold text-md"
                  onClick={() => settoggleNext(true)}
                >
                  Use Demo
                </Button>
              )}
            </div>
          </div>
          <div className="w-full">
            <TextArea
              placeholder="Problem Description"
              size="large"
              rows={12}
              value={editProblemModal?.description}
              onChange={(e) =>
                setEditProblemModal({
                  ...editProblemModal,
                  description: e.target.value,
                })
              }
              className="border-gray-400 focus:border-blue-500"
            />
          </div>

          <Modal
            open={toggleNext}
            okText="Continue"
            onOk={() => {
              setEditProblemModal((editProblemModal) => ({
                ...editProblemModal,
                description: editProblemModal.demoProblem,
              }));
              settoggleNext(false);
            }}
            onCancel={() => settoggleNext(false)}
          >
            <p className="text-red-500">
              This will overwrite your current problem
            </p>
          </Modal>
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="md:w-[70%] w-full">
            <Form.Item
              label="Library"
              className="text-md font-bold"
              validateStatus={clientErrors?.library ? "error" : ""}
              help={clientErrors?.library}
            >
              <Select
                size="large"
                placeholder="Choose Library"
                value={editProblemModal?.library || undefined}
                onChange={(value) =>
                  setEditProblemModal({ ...editProblemModal, library: value })
                }
              >
                <OptGroup label="My Promptlets">
                  {stepsData?.myPromptlets?.map((item) => (
                    <Option key={item.value} value={item.value}>
                      <div className="flex justify-between px-2">
                        <span className="truncate">{item.label}</span>
                        <span className="pl-4 text-[#8c8c8c] italic">
                          {item.author}
                        </span>
                      </div>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup
                  label="Shared Promptlets"
                  className="text-md font-bold"
                >
                  {stepsData?.communityPromptlets?.map((item) => (
                    <Option key={item.value} value={item.value}>
                      <div className="flex relative justify-between px-2">
                        <span className="truncate">{item.label}</span>
                        <span className="text-md text-[#8c8c8c] italic pl-4">
                          {item.author}
                        </span>
                      </div>
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            </Form.Item>
          </div>
          <div className="md:w-[28%] w-full">
            <Form.Item label="Language" className="text-md font-bold">
              <Select
                size="large"
                placeholder="Select language"
                value={editProblemModal?.language || undefined}
                onChange={(value) =>
                  setEditProblemModal({ ...editProblemModal, language: value })
                }
              >
                {supportedLanguages?.map((language) => (
                  <Option key={language} value={language}>
                    {language}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>,

    <div className="flex flex-col md:flex-row gap-2 justify-between items-stretch py-4">
      <div className="flex flex-col  md:w-[48%] w-full">
        <Typography.Text className="text-md font-bold py-2">
          JSON Variable String
        </Typography.Text>
        <TextArea
          value={editProblemModal?.variables}
          onChange={(e) =>
            setEditProblemModal({
              ...editProblemModal,
              variables: e.target.value,
            })
          }
          placeholder={`Enter variables JSON String`}
          className="border border-blue-500 h-full"
          rows={19}
        />
      </div>
      <div className="flex flex-col md:w-[48%] w-full">
        <Typography.Text className="text-md font-bold py-2">
          Example
        </Typography.Text>
        <JsonViewer
          value={exampleVariableJson}
          theme={customTheme}
          collapsed={false}
          displayDataTypes={false}
          displaySize={false}
          enableClipboard={true}
          rootName={false}
          className="h-full md:h-[425px] overflow-auto text-left"
        />
      </div>
    </div>,
    <div>
      <Form.Item
        label="Style Information"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="text-md font-bold py-2"
      >
        <TextArea
          value={editProblemModal?.styleInformation}
          onChange={(e) =>
            setEditProblemModal({
              ...editProblemModal,
              styleInformation: e.target.value,
            })
          }
          placeholder="Enter Style Information"
          rows={12}
          className="border-gray-400 focus:border-blue-500 "
        />
      </Form.Item>
    </div>,

    <div>
      <Form.Item
        label="Demo Problem"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ marginBottom: "16px" }}
      >
        <TextArea
          placeholder="Demo Problem"
          size="large"
          disabled={true}
          rows={12}
          value={editProblemModal?.demoProblem}
        />
      </Form.Item>
    </div>,
  ];
  return (
    <Modal
      centered
      open={editProblemModal?.open}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Update"
      confirmLoading={updateProblemLoading}
      title="Edit Problem"
      width={900}
      bodyStyle={{ minHeight: 600 }}
    >
      <div className="">
        <Tabs
          type="card"
          items={tabs.map((tabName, index) => {
            const id = String(index + 1);
            return {
              label: tabName,
              key: id,
              children: tabChildren[index],
            };
          })}
        />
      </div>
    </Modal>
  );
};

export default EditProblemModal;
