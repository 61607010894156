import React, { useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Collapse,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatePromptletStep } from "../../Redux/Features/Promptlet/updatePromptletStepSlice";
import { Tabs } from "antd";
const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const EditPromptletStepModal = ({ stepToEditModal, setStepToEditModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.updatePromptletStep);
  const [clientErrors, setClientErrors] = useState(null);

  const step = stepToEditModal.step || {};
  const buttonTabs = ["Steps"];
  const tabChildren = [
    <div className="">
      <Form form={form} initialValues={{ step }} layout="vertical">
        <Collapse accordion>
          <Panel
            header={
              <div style={{ textAlign: "left" }}>
                {`Step ${step?.key} | ${step?.title}`}
              </div>
            }
            key={step?.key}
          >
            <Card>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    label="Key"
                    name={["step", "key"]}
                    rules={[{ required: true, message: "Key is required" }]}
                  >
                    <Input placeholder="Key" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Title"
                    name={["step", "title"]}
                    rules={[{ required: true, message: "Title is required" }]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Order"
                    name={["step", "order"]}
                    rules={[{ required: true, message: "Order is required" }]}
                  >
                    <Input type="number" placeholder="Order" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Persona"
                    name={["step", "persona"]}
                    rules={[{ required: true, message: "Persona is required" }]}
                  >
                    <Input placeholder="Persona" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Task"
                    name={["step", "task"]}
                    rules={[{ required: true, message: "Task is required" }]}
                  >
                    <TextArea placeholder="Task" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Input"
                    name={["step", "input"]}
                    rules={[{ required: true, message: "Input is required" }]}
                  >
                    <TextArea placeholder="Input" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Format" name={["step", "format"]}>
                    <Input placeholder="Format" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Active" name={["step", "active"]}>
                    <Select placeholder="Active">
                      <Option value={true}>True</Option>
                      <Option value={false}>False</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="AI Checker" name={["step", "aiChecker"]}>
                    <Input placeholder="AI Checker" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="AI Checker Instructions"
                    name={["step", "aiCheckerInstructions"]}
                  >
                    <Input placeholder="AI Checker Instructions" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="AI Checker Threshold"
                    name={["step", "aiCheckerThreshold"]}
                  >
                    <Input type="number" placeholder="AI Checker Threshold" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Human Checker Style"
                    name={["step", "humanCheckerStyle"]}
                  >
                    <Select placeholder="Human Checker Style">
                      <Option value="never">Never</Option>
                      <Option value="always">Always</Option>
                      <Option value="threshold">Threshold</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Human Checker Prompt"
                    name={["step", "humanCheckerPrompt"]}
                  >
                    <Input placeholder="Human Checker Prompt" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Human Checker Threshold"
                    name={["step", "humanCheckerThreshold"]}
                  >
                    <Input
                      type="number"
                      placeholder="Human Checker Threshold"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Panel>
        </Collapse>

        {clientErrors && (
          <div className="">
            {clientErrors.map((error) => (
              <div key={error.name} className="">
                {error.errors.join(", ")}
              </div>
            ))}
          </div>
        )}
      </Form>
    </div>,
  ];
  const handleSave = (stepId) => {
    form
      .validateFields()
      .then((values) => {
        dispatch(
          updatePromptletStep({
            promptletId: stepToEditModal.promptletId,
            stepId,
            step: values,
            onSuccess: () => {},
          })
        );
      })
      .catch((errorInfo) => {
        setClientErrors(errorInfo.errorFields);
      });
  };

  return (
    <Modal
      centered
      title="Edit Promptlet Step"
      open={stepToEditModal?.open}
      onCancel={() =>
        setStepToEditModal({
          promptletId: null,
          open: false,
          step: {},
        })
      }
      footer={[
        <Button
          key="update"
          type="primary"
          onClick={() => handleSave(step._id)}
          loading={loading}
        >
          {loading ? "Updating..." : "Update"}
        </Button>,
      ]}
      width={900}
    >
      <Tabs
        type="card"
        items={buttonTabs.map((tab, index) => {
          const id = String(index + 1);
          return {
            label: tab,
            key: id,
            children: tabChildren[index],
          };
        })}
      />
    </Modal>
  );
};

export default EditPromptletStepModal;
