import React, { useEffect, useState } from "react";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { getLibrary } from "../../Redux/Features/Promptlet/getLibrarySlice";
import columns from "./Columns";
import DataSource from "./dataSource";
import AddPromptletModal from "../../components/Promptlets/addPromptletModal";
import ViewPromptletModal from "../../components/Promptlets/viewPromptletModal";
import EditPromptletModal from "../../components/Promptlets/EditPromptletModal";

const Promptlet = () => {
  const dispatch = useDispatch();
  const [promptletToAdd, setPromptletToAdd] = useState({
    open: false,
    title: "",
    steps: [],
    visibility: "sharedUnlocked",
    variables: "",
    styleInformation: "",
    demoProblem: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [promptletToEditModal, setPromptletToEditModal] = useState({
    open: false,
    copy: false,
    id: "",
    title: "",
    steps: [],
    visibility: "",
    variables: "",
    styleInformation: "",
    demoProblem: "",
  });
  const [viewPromptletModal, setViewPromptletModal] = useState({
    open: false,
    id: "",
    title: "",
    steps: [],
    visibility: "",
    variables: "",
  });

  const { data: promptlets, isLoading: getAllPrompletsLoading } = useSelector(
    (state) => state?.getLibrary
  );
  let [clientErrors, setClientErrors] = useState({});

  useEffect(() => {
    dispatch(getLibrary());
  }, []);
  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };
  return (
    <BasicLayout>
      <div className="mt-10">
        <div className="flex justify-end me-4">
          <Button
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
            onClick={() =>
              setPromptletToAdd({
                open: true,
                id: "",
                title: "",
                steps: [],
                visibility: "sharedUnlocked",
                variables: "",
                styleInformation: "",
                demoProblem: "",
              })
            }
          >
            Add Promptlet
          </Button>
        </div>
        <div className="mx-4 mt-4 rounded-lg shadow-sm">
          <Table
            bordered
            loading={getAllPrompletsLoading}
            dataSource={DataSource(
              promptlets,
              setPromptletToEditModal,
              setViewPromptletModal
            )}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              className: "py-2 rounded-lg",
              onChange: (page, pageSize) =>
                setPagination({ ...pagination, current: page, pageSize }),
              onShowSizeChange: (current, size) =>
                setPagination({ ...pagination, pageSize: size }),
            }}
            columns={columns}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <AddPromptletModal
        setPromptletToAdd={setPromptletToAdd}
        promptletToAdd={promptletToAdd}
        setClientErrors={setClientErrors}
      />

      <EditPromptletModal
        promptletToEditModal={promptletToEditModal}
        setPromptletToEditModal={setPromptletToEditModal}
        setClientErrors={setClientErrors}
        clientErrors={clientErrors}
      />
      <ViewPromptletModal
        viewPromptletModal={viewPromptletModal}
        setViewPromptletModal={setViewPromptletModal}
      />
    </BasicLayout>
  );
};

export default Promptlet;
