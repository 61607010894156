import { Table } from "antd";

const expandedRowRender = (selectedRows, problemsData) => {
  const columns = [
    {
      title: <span className="text-md font-bold"> Name </span>,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <span className="text-md font-bold"> Title </span>,
      dataIndex: "title",
      key: "title",
    },
    {
      title: <span className="text-md font-bold">Content</span>,
      dataIndex: "content",
      key: "content",
    },
  ];

  const responses = problemsData?.map((item) => {
    return item?.response;
  });

  for (let key in responses) {
    const item = responses[key];
    if (key?.toString() == selectedRows?.key?.toString()) {
      return <Table columns={columns} dataSource={item} pagination={false} />;
    }
  }
};

export default expandedRowRender;
