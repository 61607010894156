import { formatDateToStandard, getCookie } from "../../utils/helper";
import { Button, Tooltip } from "antd";
import handleStepsData from "../../utils/home/handleStepsData";
import handleDeleteProblem from "../../utils/home/handleDeleteProblem";
import { restartProblem } from "../../Redux/Features/Problem/restartProblemSlice";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { addConversation } from "../../Redux/Features/Conversation/addConversationSlice";
import { useNavigate } from "react-router-dom";
const DataSource = (problemsData, getLibraryData, setEditProblemModal) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleStart = (item) => {
    const conversationId = uuidv4();
    const userId = getCookie("userId");
    localStorage.setItem("conversationId", conversationId);
    dispatch(
      addConversation({
        conversationId,
        userId,
        problemId: item?._id,
        onSuccess: () => {
          navigate(`/chat/${item?._id}`);
        },
      })
    );
  };
  const handleRestart = (item) => {
    const conversationId = uuidv4();
    const userId = getCookie("userId");
    localStorage.setItem("conversationId", conversationId);
    dispatch(
      addConversation({
        conversationId,
        userId,
        problemId: item?._id,
        onSuccess: () => {
          dispatch(
            restartProblem({
              id: item?._id,
              onSuccess: () => {
                navigate(`/chat/${item?._id}`);
              },
            })
          );
        },
      })
    );
  };

  return problemsData?.map((item, i) => ({
    key: i,
    date: <>{formatDateToStandard(item?.created_at)}</>,
    title: item?.title,
    description: (
      <Tooltip title={item?.description}>{`${item?.description?.slice(0, 50)}${
        item?.description?.length > 78 ? "..." : ""
      }`}</Tooltip>
    ),
    library: (
      <Tooltip title={handleStepsData(item?.library, getLibraryData)}>
        {handleStepsData(item?.library, getLibraryData)?.slice(0, 30) + "  ..."}
      </Tooltip>
    ),
    language: item?.language,

    action: (
      <div className="flex gap-2">
        <Button
          className="text-md font-bold text-red-500 hover:!bg-red-500 hover:!text-white"
          onClick={() => handleDeleteProblem(item?._id, dispatch)}
        >
          Delete
        </Button>

        <Button
          className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          onClick={() => {
            setEditProblemModal({
              open: true,
              id: item?._id,
              title: item?.title,
              description: item?.description,
              library: item?.library,
              language: item?.language,
              variables: item?.variables,
              styleInformation: item?.styleInformation,
              demoProblem: item?.demoProblem,
            });
          }}
        >
          Edit
        </Button>

        {Number(item?.stepCount) === 0 && (
          <Button
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
            onClick={() => handleStart(item)}
          >
            Start
          </Button>
        )}
        {Number(item?.stepCount) > 0 && (
          <Button
            className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
            onClick={() => {
              handleRestart(item);
            }}
          >
            Restart
          </Button>
        )}
        {Number(item?.stepCount) > 0 &&
          Number(item?.stepCount) <= Number(item?.maxCount) && (
            <Button
              className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
              onClick={() => navigate(`/chat/${item?._id}`)}
            >
              View
            </Button>
          )}
      </div>
    ),
  }));
};

export default DataSource;
