const columns = [
  {
    title: <span className="text-md font-bold">Date</span>,
    dataIndex: "date",
    key: "1",
    render: (text) => <span className="text-gray-800">{text}</span>,
  },
  {
    title: <span className="text-md font-bold">Title</span>,
    dataIndex: "title",
    key: "2",
    render: (text) => <span className="text-gray-800">{text}</span>,
  },
  {
    title: <span className="text-md font-bold">Description</span>,
    dataIndex: "description",
    key: "3",
    render: (text) => <span className="text-gray-800">{text}</span>,
  },
  {
    title: <span className="text-md font-bold">Promptlet </span>,
    dataIndex: "library",
    key: "4",
    render: (text) => <span className="text-gray-800">{text}</span>,
  },
  {
    title: <span className="text-md font-bold"> Language</span>,
    dataIndex: "language",
    key: "5",
    render: (text) => <span className="text-gray-800">{text}</span>,
  },
  {
    title: <span className="text-md font-bold"> Action</span>,
    dataIndex: "action",
    key: "5",
    render: (text) => <span className="text-gray-800">{text}</span>,
  },
];

export default columns;
