import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../Config";
import axios from "axios";
import { axiosErrorHandler } from "../../../utils/helper";

const initialState = {
  isLoading: false,
  isError: null,
  errors: [],
  isSuccess: false,
  message: "",
  step: {},
};

export const getPromptletStep = createAsyncThunk(
  "Library/Get-Library",
  async ({ promptletId, stepId, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/library/${promptletId}/steps/${stepId}`
      );
      onSuccess && onSuccess(response?.data?.data);
      return response?.data;
    } catch (error) {
      axiosErrorHandler(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const getPromptletStepSlice = createSlice({
  name: "Get promptlet",
  initialState: initialState,
  reducers: {
    resetPromptlet: (state) => {
      state.step = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPromptletStep.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(getPromptletStep.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errors = [];
      state.isSuccess = action?.payload?.success;
      state.message = action?.payload?.message;
      state.step = action?.payload?.data;
    });
    builder.addCase(getPromptletStep.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload?.errors || [];
      state.isError = true;
    });
  },
});

export const { resetPromptlet, deletePromptletData } =
  getPromptletStepSlice?.actions;

export default getPromptletStepSlice?.reducer;
