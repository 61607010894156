import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Typography,
  Row,
  Col,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import saveAsNewPromptlet from "../../utils/promptlet/saveAsNewPromptlet";
import TextArea from "antd/es/input/TextArea";
import { JsonViewer } from "@textea/json-viewer";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import { customTheme } from "../../utils/themes/jsonViewerTheme";

const { Option } = Select;
const AddPromptletModal = ({
  setPromptletToAdd,
  promptletToAdd,
  setClientErrors,
}) => {
  const dispatch = useDispatch();
  const { isLoading: addPromptletLoading } = useSelector(
    (state) => state?.addPromptlet
  );
  const [form] = Form.useForm();
  const [steps, setSteps] = useState(promptletToAdd?.steps || []);
  const handleAddStep = () => {
    setSteps([...steps, {}]);
  };

  const handleRemoveStep = (index) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        saveAsNewPromptlet(
          {
            ...promptletToAdd,
            ...values,
          },
          setClientErrors,
          dispatch,
          setPromptletToAdd
        );
      })
      .catch((error) => {
        console.error("Validation Failed:", error);
      });
  };

  const exampleVariableJson = {
    variables: [
      {
        name: "variablename1",
        type: "text",
        value: { content: "John Doe" },
      },
      {
        name: "variablename2",
        type: "image",
        value: {
          content: "image/jpeg",
          url: "bit.ly:3VgENUT",
        },
      },
    ],
  };
  const visibilityOptions = ["private", "sharedUnlocked", "sharedLocked"];
  const tabs = ["Steps", "Variables", "Defaults", "Demo Problem"];
  const tabChildren = [
    <div>
      {steps.map((step, index) => (
        <div key={index} className="">
          <Row gutter={16}>
            <Typography.Text style={{ textAlign: "left" }}>
              Step {index + 1}{" "}
            </Typography.Text>
            <Col span={24}>
              <Button
                type="text"
                danger
                icon={<MinusCircleOutlined />}
                onClick={() => handleRemoveStep(index)}
                style={{ float: "right" }}
              >
                Remove Step
              </Button>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Key"
                name={["steps", index, "key"]}
                rules={[{ required: true, message: "Key is required" }]}
              >
                <Input placeholder="Key" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Title"
                name={["steps", index, "title"]}
                rules={[{ required: true, message: "Title is required" }]}
              >
                <Input placeholder="Title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Order"
                name={["steps", index, "order"]}
                rules={[{ required: true, message: "Order is required" }]}
              >
                <Input type="number" placeholder="Order" min={1} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Persona"
                name={["steps", index, "persona"]}
                rules={[{ required: true, message: "Persona is required" }]}
              >
                <Input placeholder="Persona" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Task"
                name={["steps", index, "task"]}
                rules={[{ required: true, message: "Task is required" }]}
              >
                <TextArea placeholder="Task" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Input"
                name={["steps", index, "input"]}
                rules={[{ required: true, message: "Input is required" }]}
              >
                <TextArea placeholder="Input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Format" name={["steps", index, "format"]}>
                <Input placeholder="Format" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Active" name={["steps", index, "active"]}>
                <Select placeholder="Active" defaultValue={true}>
                  <Option value={true}>True</Option>
                  <Option value={false}>False</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="AI Checker"
                name={["steps", index, "aiChecker"]}
              >
                <Input placeholder="AI Checker" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="AI Checker Instructions"
                name={["steps", index, "aiCheckerInstructions"]}
              >
                <Input placeholder="AI Checker Instructions" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="AI Checker Threshold"
                name={["steps", index, "aiCheckerThreshold"]}
              >
                <Input type="number" placeholder="AI Checker Threshold" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Human Checker Style"
                name={["steps", index, "humanCheckerStyle"]}
              >
                <Select placeholder="Human Checker Style" defaultValue="never">
                  <Option value="never">Never</Option>
                  <Option value="always">Always</Option>
                  <Option value="threshold">Threshold</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Human Checker Prompt"
                name={["steps", index, "humanCheckerPrompt"]}
              >
                <Input placeholder="Human Checker Prompt" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Human Checker Threshold"
                name={["steps", index, "humanCheckerThreshold"]}
              >
                <Input type="number" placeholder="Human Checker Threshold" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ))}
      <Button
        type="dashed"
        onClick={handleAddStep}
        style={{ width: "100%", marginBottom: "20px" }}
        icon={<PlusOutlined style={{ color: "limegreen" }} />}
      >
        Add Step
      </Button>
    </div>,

    <div className="flex flex-col md:flex-row gap-2 justify-between items-stretch py-4">
      <div className="flex flex-col md:w-[48%] w-full">
        <Form.Item
          label="JSON Variable String"
          className="text-md font-bold py-2"
        >
          <TextArea
            label="Variables"
            value={promptletToAdd.variables}
            onChange={(e) =>
              setPromptletToAdd((promptletToAdd) => ({
                ...promptletToAdd,
                variables: e.target.value,
              }))
            }
            placeholder={`Enter variables JSON String`}
            rows={19}
          />
        </Form.Item>
      </div>
      <div className="flex flex-col md:w-[48%] w-full">
        <Form.Item label="Example" className="text-md font-bold py-2">
          <JsonViewer
            value={exampleVariableJson}
            theme={customTheme}
            collapsed={false}
            displayDataTypes={false}
            displaySize={false}
            enableClipboard={true}
            rootName={false}
            className="h-full md:h-[425px] text-left"
          />
        </Form.Item>
      </div>
    </div>,
    <div>
      <Form.Item
        className="text-md font-bold py-2"
        label="Default writing style"
      >
        <TextArea
          value={promptletToAdd.styleInformation}
          onChange={(e) =>
            setPromptletToAdd((promptletToAdd) => ({
              ...promptletToAdd,
              styleInformation: e.target.value,
            }))
          }
          placeholder={`Enter Styles Information`}
          rows={12}
        />
      </Form.Item>
    </div>,
    <div>
      <Form.Item className="text-md font-bold" label="Demo Problem">
        <TextArea
          value={promptletToAdd.demoProblem}
          onChange={(e) =>
            setPromptletToAdd((promptletToAdd) => ({
              ...promptletToAdd,
              demoProblem: e.target.value,
            }))
          }
          placeholder={`Enter your demo problem`}
          rows={12}
        />
      </Form.Item>
    </div>,
  ];
  return (
    <Modal
      centered
      open={promptletToAdd?.open}
      onCancel={() => {
        setPromptletToAdd({
          open: false,
          title: "",
          steps: [],
          visibility: "sharedUnlocked",
          variables: "",
          styleInformation: "",
          demoProblem: "",
        });
      }}
      width={900}
      bodyStyle={{ minHeight: 600 }}
      title="Add Promptlet"
      footer={[
        <Button
          key="save"
          className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
          onClick={handleSave}
          loading={addPromptletLoading}
        >
          {addPromptletLoading ? (
            "Saving"
          ) : (
            "Save"
          )}
        </Button>,
      ]}
    >
      <div>
        <Form form={form} initialValues={{ steps }} layout="vertical">
          <div className="flex flex-col md:flex-row gap-2">
            <div className="flex flex-col md:w-[48%] w-full">
              <Form.Item
                label="Promptlet Title"
                name="title"
                rules={[{ required: true, message: "Please enter a title" }]}
                className="text-md font-bold"
              >
                <Input
                  placeholder="Promptlet Title"
                  size="large"
                  value={promptletToAdd.title}
                  onChange={(e) =>
                    setPromptletToAdd((promptletToAdd) => ({
                      ...promptletToAdd,
                      title: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </div>
            <div className="flex flex-col md:w-[48%] w-full">
              <Form.Item label="Visibility" className="text-md font-bold">
                <Select
                  value={promptletToAdd?.visibility || "sharedUnlocked"}
                  onChange={(value) => {
                    setPromptletToAdd((prevPromptlet) => ({
                      ...prevPromptlet,
                      visibility: value,
                    }));
                  }}
                  className="h-[40px]"
                >
                  {visibilityOptions.map((option, index) => (
                    <Option value={option} key={index}>
                      {option === "private" && <p>Private</p>}
                      {option === "sharedUnlocked" && <p>Shared (Unlocked)</p>}
                      {option === "sharedLocked" && <p>Shared (Locked)</p>}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Tabs
            type="card"
            items={tabs.map((tabName, index) => {
              const id = String(index + 1);
              return {
                label: tabName,
                key: id,
                children: tabChildren[index],
              };
            })}
          />
        </Form>
      </div>
    </Modal>
  );
};

export default AddPromptletModal;
