import { getProblem } from "../../../Redux/Features/Problem/getProblemSlice";
import addUserChat from "../Chat/addUserChats";
import handleMessage from "../Chat/handleMessage";

const addProblemDataUtility = (
  dispatch,
  setIsResume,
  setChats,
  setIsChatCompleted,
  chats,
  setMessage,
  setIsChat,
  setIsTyping,
  navigate,
  problemData,
  problemId,
  message
) => {
  dispatch(
    getProblem({
      id: problemId,
      onSuccess: (data) => {
        const currentProblem = data?.data;
        if (currentProblem?.stepCount > 0 && currentProblem?.stepCount <= currentProblem?.maxCount) {
          setIsResume(true);
          if (chats?.length === 0) {
            addUserChat(
              chats,
              problemData?.description || currentProblem?.description,
              setChats
            );
          }
          if (currentProblem?.stepCount === currentProblem?.maxCount) {
            setIsChatCompleted(true);
          }
        } else {
          handleMessage(
            currentProblem,
            setChats,
            chats,
            setMessage,
            setIsChat,
            dispatch,
            setIsTyping,
            navigate,
            problemData,
            problemId,
            message,
          );
        }
      },
    })
  );
};

export default addProblemDataUtility;
