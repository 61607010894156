const columns = [
  {
    title: <span className="text-md font-bold">Date</span>,
    dataIndex: "date",
    key: "1",
  },
  {
    title: <span className="text-md font-bold"> Title </span>,
    dataIndex: "title",
    key: "2",
  },
  {
    title: <span className="text-md font-bold">Description</span>,
    dataIndex: "description",
    key: "3",
  },
  {
    title: <span className="text-md font-bold">Promptlet</span>,
    dataIndex: "promptlet",
    key: "4",
  },
  {
    title: <span className="text-md font-bold"> Author</span>,
    dataIndex: "author",
    key: "5",
  },
  {
    title: <span className="text-md font-bold"> Total Thread Cost</span>,
    dataIndex: "cost",
    key: "6",
  },
];

export default columns;
