import React, { useState } from "react";
import { Col, Typography, Button } from "antd";
import DebugViewerModal from "./Modals/debugViewerModal";
import styles from "../Pages/ProblemConversation/ProblemConversation.module.scss";
import ChatItem from "./chatItem";
import handleResume from "../utils/problemConversation/Actions/handleResume";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditPromptletStepModal from "./Promptlets/EditPromptletStepModal";

const ChatList = ({
  chats,
  isMdOrAbove,
  isSMOrBellow,
  isTyping,
  isResume,
  isChatCompleted,
  isWritting,
  setIsWritting,
  setChats,
  editedResponse,
  setEditedResponse,
  setIsEditorModalVisible,
  setIsChat,
  setIsTyping,
  setMessage,
  problemData,
  problemId,
  message,
  setIsResume,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDebugModal, setOpenDebugModal] = useState(false);
  const [stepToEditModal, setStepToEditModal] = useState({
    open: false,
    promptletId: null,
    step: {},
  });
  return (
    <div className={styles.chats_container}>
      {chats?.map((item, i) => (
        <ChatItem
          key={i}
          item={item}
          i={i}
          chats={chats}
          isMdOrAbove={isMdOrAbove}
          isSMOrBellow={isSMOrBellow}
          isWritting={isWritting}
          setIsWritting={setIsWritting}
          isResume={isResume}
          setChats={setChats}
          setOpenDebugModal={setOpenDebugModal}
          setStepToEditModal={setStepToEditModal}
          editedResponse={editedResponse}
          setEditedResponse={setEditedResponse}
          setIsEditorModalVisible={setIsEditorModalVisible}
          setIsChat={setIsChat}
          setIsTyping={setIsTyping}
          setMessage={setMessage}
          problemData={problemData}
          problemId={problemId}
          message={message}
        />
      ))}

      {isResume && !isChatCompleted && (
        <Col>
          <Button
           className="!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
            onClick={() =>
              handleResume(
                setChats,
                chats,
                setMessage,
                setIsChat,
                dispatch,
                setIsTyping,
                navigate,
                problemData,
                problemId,
                message,
                setIsResume
              )
            }
          >
            Resume
          </Button>
        </Col>
      )}

      {!isResume && isTyping && (
        <div className="">
          <Typography.Paragraph className="">Typing...</Typography.Paragraph>
        </div>
      )}

      <DebugViewerModal
        open={openDebugModal}
        onCancel={() => setOpenDebugModal(false)}
      />
      <EditPromptletStepModal
        stepToEditModal={stepToEditModal}
        setStepToEditModal={setStepToEditModal}
      />
    </div>
  );
};

export default ChatList;
