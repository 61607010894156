const columns = [
  {
    title: <span className="text-md font-bold"> Date</span>,
    dataIndex: "date",
    key: "1",
  },
  {
    title: <span className="text-md font-bold"> Title</span>,
    dataIndex: "title",
    key: "2",
  },
  {
    title: <span className="text-md font-bold"> Author Name </span>,
    dataIndex: "name",
    key: "3",
  },
  {
    title: <span className="text-md font-bold"> Visibility </span>,
    dataIndex: "visibilityElement",
    key: "4",
    filters: [
      { text: "All", value: "all" },
      { text: "Private", value: "private" },
      {
        text: "Shared UnLocked",
        value: "sharedUnlocked",
      },
      { text: "Shared Locked", value: "sharedLocked" },
    ],
    onFilter: (value, record) => {
      if (value === "all") {
        return true;
      }
      return record.visibility === value;
    },
    defaultFilteredValue: ["private"],
  },
  {
    title: <span className="text-md font-bold">Action</span>,
    dataIndex: "action",
    key: "5",
  },
];

export default columns;
