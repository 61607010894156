import { configureStore } from "@reduxjs/toolkit";
import getUserSlice from "./Features/User/getUserSlice";
import addProblemSlice from "./Features/Problem/addProblemSlice";
import getAllProblemsSlice from "./Features/Problem/getAllProblemsSlice";
import updateProblemSlice from "./Features/Problem/updateProblemSlice";
import getProblemSlice from "./Features/Problem/getProblemSlice";
import addChatSlice from "./Features/Chat/addChatSlice";
import acceptResponseSlice from "./Features/Chat/acceptResponseSlice";
import userLogoutSlice from "./Features/User/userLogoutSlice";
import restartProblemSlice from "./Features/Problem/restartProblemSlice";
import deleteProblemSlice from "./Features/Problem/deleteProblemSlice";
import getAllUsersSlice from "./Features/User/getAllUsersSlice";
import deleteUserSlice from "./Features/User/deleteUserSlice";
import getAllLogsOfStepInSpecificConversationSlice from "./Features/Log/getAllStepLogsOfConversationSlice";
import addConversationSlice from "./Features/Conversation/addConversationSlice";
import addLogSlice from "./Features/Log/addLogSlice";
import AIEvaluationSlice from "./Features/Chat/AIEvaluationSlice";
import AIRequerySlice from "./Features/Chat/AIRequerySlice";
import deletePromptletSlice from "./Features/Promptlet/deletePromptletSlice";
import getLibrarySlice from "./Features/Promptlet/getLibrarySlice";
import addPromptletSlice from "./Features/Promptlet/addPromptletSlice";
import updatePromptletSlice from "./Features/Promptlet/updatePromptletSlice";
import compileChatsToDocumentSlice from "./Features/Document/compileDocumentSlice"
import mergeChatsToDocumentSlice from "./Features/Document/mergeDocumentSlice";
import getAllUserThreadsSlice from "./Features/Thread/getAllUserThreadsSlice";
import getAllThreadsSlice from "./Features/Thread/getAllThreads"
import getPromptletSlice from "./Features/Promptlet/getPromptletSlice";
import getPromptletStepSlice from "./Features/Promptlet/getPromptletStepSlice";
import updatePromptletStepSlice from "./Features/Promptlet/updatePromptletStepSlice";
export const store = configureStore({
  reducer: {
    getUser: getUserSlice,
    deleteUser: deleteUserSlice,
    addProblem: addProblemSlice,
    getAllProblems: getAllProblemsSlice,
    updateProblem: updateProblemSlice,
    getProblem: getProblemSlice,
    addChat: addChatSlice,
    acceptResponse: acceptResponseSlice,
    userLogout: userLogoutSlice,
    getAllUserThreads: getAllUserThreadsSlice,
    getAllThreads:getAllThreadsSlice,
    getAllUsers: getAllUsersSlice,
    restartProblem: restartProblemSlice,
    addPromptlet: addPromptletSlice,
    updatePromptlet: updatePromptletSlice,
    getLibrary: getLibrarySlice,
    getPromptlet:getPromptletSlice,
    deletePromptlet: deletePromptletSlice,
    deleteProblem: deleteProblemSlice,
    addLog: addLogSlice,
    getAllLogsOfStepInSpecificConversation:
    getAllLogsOfStepInSpecificConversationSlice,
    addConversation: addConversationSlice,
    AIEvaluation: AIEvaluationSlice,
    AIRequery: AIRequerySlice,
    compileChatsToDocument:compileChatsToDocumentSlice,
    mergeChatsToDocument:mergeChatsToDocumentSlice,
    getPromptletStep:getPromptletStepSlice,
    updatePromptletStep:updatePromptletStepSlice
  },
});
