import React, { useEffect, useState } from "react";
import BasicLayout from "../../Layout/BasicLayout";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { getAllUserThreads } from "../../Redux/Features/Thread/getAllUserThreadsSlice";
import DataSource from "./Common/DataSource";
import columns from "./Common/Columns";
import expandedRowRender from "./Common/ExpandedRowRender";
const Threads = () => {
  const dispatch = useDispatch();

  const { data: getAllUserThreadsData, isLoading: getAllUserThreadsLoading } =
    useSelector((state) => state?.getAllUserThreads);

  const [problemsData, setProblemsData] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(getAllUserThreads());
  }, []);
  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };
  useEffect(() => {
    if (getAllUserThreadsData?.length > 0) {
      const modifiedThreadsData = getAllUserThreadsData?.map((thread) => {
        let totalCost = 0;
        const response = thread?.response?.map((res, i) => {
          const key = Object?.keys(res)?.find((key) => key != "stepId");
          // Sum up the prompt and response token costs
          const promptTokenCost = res.promptTokenCost || 0;
          const responseTokenCost = res.responseTokenCost || 0;
          totalCost += promptTokenCost + responseTokenCost;
          return {
            key: i,
            name: key,
            title: res[key]?.title,
            content: res[key]?.content,
          };
        });
        return {
          ...thread,
          response: response,
          problem: thread?.problem,
          totalCost: totalCost,
        };
      });
      setProblemsData(modifiedThreadsData);
    }
  }, [getAllUserThreadsData]);

  return (
    <BasicLayout>
      <div className="">
        <div className="mx-4 mt-4 rounded-lg shadow-sm">
          <Table
            bordered
            loading={getAllUserThreadsLoading}
            dataSource={DataSource(problemsData)}
            columns={columns}
            expandable={{
              expandedRowRender: (record) =>
                expandedRowRender(record, problemsData),
              onExpand: (data) => {
                setSelectedProblem((prev) => {
                  return [...prev, data?.key?.toString()];
                });
              },
              defaultExpandedRowKeys: ["0"],
            }}
            onChange={handleTableChange}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              className: "py-2 rounded-lg",
              onChange: (page, pageSize) =>
                setPagination({ ...pagination, current: page, pageSize }),
              onShowSizeChange: (current, size) =>
                setPagination({ ...pagination, pageSize: size }),
            }}
          />
        </div>
      </div>
    </BasicLayout>
  );
};

export default Threads;
