const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "1",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "2",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "3",
  },
  {
    title: "Debug Level",
    dataIndex: "debugLevel:",
    key: "4",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "5",
  },
];

export default columns;
