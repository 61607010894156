import { getAllLogsOfStepInSpecificConversation } from "../../../Redux/Features/Log/getAllStepLogsOfConversationSlice";

const handleDebug = async (stepId, dispatch) => {
  dispatch(
    getAllLogsOfStepInSpecificConversation({
      stepId,
    })
  );
};

export default handleDebug;
