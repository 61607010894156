import React, { useEffect } from "react";
import gsap from "gsap";
const AnimatedTitle = ({ title, classContainer }) => {
  useEffect(() => {
    gsap.fromTo(
      ".about-title",
      { x: -115, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        stagger: 0.1,
        delay: 0.2,
        duration: 1,
        ease: "power2.out",
      }
    );
  }, []);
  return (
    <div className={`${classContainer}`}>
      {title.split("<br/>").map((line, indx) => (
        <div key={indx} className="flex gap-1">
          {line.split(" ").map((word, i) => (
            <p
              className="text-4xl uppercase about-title font-bold special-font text-black"
              key={i}
            >
              {word === "AutoBA" ? (
                <span className="special-font text-blue-600">
                  <b>A</b>u<b>t</b>oB<b>A</b>
                </span>
              ) : (
                word
              )}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AnimatedTitle;
