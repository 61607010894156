import { addChat } from "../../../Redux/Features/Chat/addChatSlice";

const handleRequeryResponse = async (chats,setChats,setIsTyping,dispatch,navigate,problemId,problemData) => {
    const newChats = chats.slice(0, -1);
    setChats(newChats);
    setIsTyping(true);
    dispatch(
      addChat({
        id: problemId,
        role: "user",
        content: problemData?.description,
        additionalInformation:
          "The previous response wasn't good please generate better response",
        debugLevel: 0,
        action: "requiry",
        onSuccess: (data) => {
          setIsTyping(false);
          if (data?.status === "STEPS_COMPLETED") {
            navigate("/");
          }
        },
        onError: () => {
          setIsTyping(false);
        },
      })
    );
  };


  export default handleRequeryResponse;