import React, { useState } from "react";
import { Button } from "antd";
import { BASE_URL } from "../../Config";
import AnimatedTitle from "../../components/Common/animatedTitle";
import { AiOutlineGoogle } from "react-icons/ai";
import { FaEnvelope, FaEye, FaEyeSlash, FaVoicemail } from "react-icons/fa";

const Register = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleRegister = (type) => {
    if (!type) {
      return "";
    }
    sessionStorage?.setItem("authType", type);
    window.location = `${BASE_URL}/api/auth/google`;
  };

  return (
    <div className="h-screen flex items-center justify-center bg-transparent">
      <div className="flex flex-col gap-2 items-center justify-between bg-white border-2 md:w-1/2 w-full md:h-[550px] h-full rounded-md">
        <AnimatedTitle title="Welcome to AutoBA" classContainer="py-4" />
        <div>
          <p className="text-lg font-extralight text-black">
            Make Your{" "}
            <span className="capitalize underline text-blue-600 font-bold">
              bussiness
            </span>{" "}
            Grow.
          </p>
        </div>
        <div className="flex flex-col gap-4 items-center">
          <div className="flex items-center">
            <div className="h-1 w-5 bg-pink-500" />
            <div className="h-1 w-5 bg-pink-500" />
            <p className="text-black px-2">
              Login With{" "}
              <span className="text-xl font-bold uppercase text-blue-600">
                Google
              </span>
            </p>
            <div className="h-1 w-5 bg-pink-500" />
            <div className="h-1 w-5 bg-pink-500" />
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => handleRegister("SIGN_IN")}
              className="flex gap-1 items-center !border-blue-600"
            >
              <AiOutlineGoogle
                className="text-blue-600 text-center"
                fontSize={30}
              />{" "}
              <p className="font-bold text-xl">Log in</p>
            </Button>
          </div>
        </div>
        <div className="h-1 w-full bg-gray-200 my-4" />

        <div className="flex flex-col gap-4 py-4">
          {/*  */}
          <div className="flex flex-col gap-2">
            <label className="text-black">Email</label>
            <div className="relative">
              <FaEnvelope
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-600"
                fontSize={20}
              />
              <input
                type="email"
                className="pl-10 text-black bg-gray-300 rounded-md focus-visible:border-blue-600 px-2 py-1 w-full"
                placeholder="Enter your email"
              />
            </div>
          </div>
          {/*  */}
          <div className="flex flex-col gap-2">
            <label className="text-black">Password</label>
            <div className="relative">
              <input
                type={passwordVisible ? "text" : "password"}
                className="pl-10 pr-10 text-black bg-gray-300 rounded-md focus-visible:border-blue-600 px-2 py-1 w-full"
                placeholder="Enter your password"
              />
              <FaEye
                onClick={togglePasswordVisibility}
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-600 cursor-pointer ${
                  passwordVisible ? "hidden" : "block"
                }`}
                fontSize={20}
              />
              <FaEyeSlash
                onClick={togglePasswordVisibility}
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-600 cursor-pointer ${
                  passwordVisible ? "block" : "hidden"
                }`}
                fontSize={20}
              />
            </div>
          </div>
          <Button className="!bg-blue-600 text-white font-bold hover:!bg-blue-500 hover:!text-white">
            Login
          </Button>
        </div>
        <div className="flex gap-1 py-4">
          <p className="text-black">Don't have an account?</p>{" "}
          <a
            className="text-blue-500 cursor-pointer"
            onClick={() => handleRegister("SIGN_UP")}
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
};

export default Register;
