import React from "react";

const ProfileAvator = ({ url, setshowProfile }) => {
  return (
    <div className="">
      <img
        src={url}
        className="rounded-full w-10 h-10 cursor-pointer"
        onClick={() => setshowProfile(true)}
      />
    </div>
  );
};

export default ProfileAvator;
