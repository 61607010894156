import { Tooltip } from "antd";
import { formatDateToStandard } from "../../../utils/helper";

const DataSource = (problemsData) => {
  return problemsData?.map((item, i) => {
    return {
      key: i,
      date: <p className="">{formatDateToStandard(item?.created_at)}</p>,
      title: <p className="">{item?.problem?.title}</p>,
      description: (
        <p className="">
          <Tooltip
            title={item?.problem?.description}
          >{`${item?.problem?.description?.slice(0, 50)}${
            item?.problem?.description?.length > 78 ? "..." : ""
          }`}</Tooltip>
        </p>
      ),
      promptlet: <p className="">{item?.problem?.library?.name}</p>,
      author: <p className="">{item?.user?.email}</p>,
      cost: <p className="">${item.totalCost.toFixed(2)}</p>,
    };
  });
};

export default DataSource;
