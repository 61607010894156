import React from "react";
import { Button, Typography } from "antd";
import BasicLayout from "../../Layout/BasicLayout";
import { useNavigate } from "react-router-dom";
import AnimatedTitle from "../../components/Common/animatedTitle";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import plantumlImage from "../../assets/images/plantuml.png";
const About = () => {
  const navigate = useNavigate();
  const plantumlCode = `@startuml
actor User
participant "Web Browser" as Browser
participant "Web Server" as Server
participant "Database" as DB

User -> Browser: Enters URL
Browser -> Server: Sends HTTP Request
Server -> DB: Queries for Data
DB --> Server: Returns Data
Server --> Browser: Sends HTTP Response
Browser --> User: Displays Web Page
@enduml
`;
  return (
    <BasicLayout>
      <div className="flex flex-col bg-white">
        <div className="flex flex-col items-center gap-4 my-4 mx-4">
          <AnimatedTitle title="What is AutoBA ?" classContainer="my-4" />
          <div className="flex flex-col items-start gap-0">
            <Typography.Paragraph className="text-lg font-extralight">
              <span className="uppercase special-font text-xl font-bold">
                {" "}
                <b>A</b>u<b>t</b>oB<b>A</b>
              </span>{" "}
              is a web-based business analysis and diagram creation tool that
              leverages Generative AI and PlantUML.
            </Typography.Paragraph>
            <Typography.Paragraph className="text-lg font-extralight">
              Using the OpenAI API, we generate text-based descriptions and UML
              code based on user-provided scenarios.
            </Typography.Paragraph>
            <Typography.Paragraph className="text-lg font-extralight">
              This generated UML code is then processed by the PlantUML JAR file
              to create visual diagrams in image format.
            </Typography.Paragraph>
            <Typography.Paragraph className="text-lg font-extralight">
              Additionally, we are expanding our capabilities to include BPMN.io
              for advanced business process modeling.
            </Typography.Paragraph>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-6 justify-between items-stretch mx-4 py-6">
          <div className="md:w-1/2 w-full bg-gray-100 p-1 rounded shadow-md">
            <SyntaxHighlighter language="plantuml" style={docco} className="h-full">
              {plantumlCode}
            </SyntaxHighlighter>
          </div>
          <div className="md:w-1/2 w-full bg-gray-100 p-1 rounded shadow-md">
            <img
              src={plantumlImage}
              alt="Generated Diagram"
              className="object-contain w-full h-auto rounded"
            />
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default About;
