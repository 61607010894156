import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosErrorHandler, getCookie } from "../../../utils/helper";
import setAuthToken from "../../../utils/axios/setAuthToken";
import axios from "axios";
import { BASE_URL } from "../../../Config";

export const AIEvaluation = createAsyncThunk(
  "AI evaluation",
  async (
    {
      problemId,
      previousUserMessage,
      aiInstruction,
      aichecker,
      currentResponse,
      language,
      stepId,
      libraryId,
      debugLevel,
      action,
      onSuccess,
      onError
    },
    { rejectWithValue }
  ) => {
    try {
      const token = getCookie("token");
      setAuthToken(token);
      const conversationId = localStorage.getItem("conversationId");
      const response = await axios.post(`${BASE_URL}/api/user/chats/aiEval/${problemId}`, {
        previousUserMessage,
        aiInstruction,
        aichecker,
        currentResponse,
        language,
        conversationId,
        stepId,
        libraryId,
        debugLevel,
        action,
      });
      onSuccess&&onSuccess();
      return response?.data;
    } catch (error) {
      onError&&onError()
      axiosErrorHandler(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
  data: [],
};
const AIEvaluationSlice = createSlice({
  name: "AI evaluation",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AIEvaluation.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(AIEvaluation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = action?.payload?.message;
      state.data = action?.payload?.data;
    });
    builder.addCase(AIEvaluation.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.message = action?.payload?.message;
    });
  },
});

export default AIEvaluationSlice?.reducer;
