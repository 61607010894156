import React from "react";
import { Input, Modal, Select, Form } from "antd";
import handleStepsData from "../../utils/home/handleStepsData";
import saveAsNewProblem from "../../utils/home/saveAsNewProblem";
const { TextArea } = Input;
const { Option, OptGroup } = Select;

const AddProblemModal = ({
  addProblemModal,
  addProblemLoading,
  setProblem,
  setAddProblemModal,
  setEditProblemModal,
  clientErrors,
  promptlets,
  problem,
  setClientErrors,
  dispatch,
}) => {
  const supportedLanguages = [
    "English",
    "Spanish",
    "French",
    "German",
    "Portuguese",
    "Italian",
    "Dutch",
    "Russian",
    "Chinese (Simplified)",
    "Chinese (Traditional)",
    "Japanese",
    "Korean",
    "Arabic",
    "Hindi",
    "Bengali",
    "Urdu",
    "Persian/Farsi",
    "Turkish",
    "Polish",
    "Swedish",
    "Danish",
    "Norwegian",
    "Finnish",
    "Greek",
    "Thai",
    "Hebrew",
    "Indonesian",
    "Vietnamese",
    "Malay",
    "Romanian",
    "Czech",
    "Hungarian",
    "Slovak",
    "Ukrainian",
    "Bulgarian",
    "Serbian",
    "Croatian",
  ];
  const handleCancel = () => {
    setProblem({
      title: "",
      description: "",
      library: "",
      language: "",
    });
    setAddProblemModal(false);
    setClientErrors({});
  };

  const handleSave = () => {
    saveAsNewProblem(
      problem,
      setClientErrors,
      dispatch,
      setProblem,
      setAddProblemModal,
      setEditProblemModal
    );
  };

  const stepsData = handleStepsData(null, promptlets);

  return (
    <Modal
      centered
      open={addProblemModal}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Save"
      confirmLoading={addProblemLoading}
      width={900}
      title="Add Problem"
    >
      <div className="flex flex-col">
        <Form layout="vertical">
          <Form.Item
            label="Problem Title"
            validateStatus={clientErrors?.title ? "error" : ""}
            help={clientErrors?.title}
            className="text-md font-bold"
          >
            <Input
              placeholder="Problem Title"
              size="large"
              value={problem?.title}
              onChange={(e) =>
                setProblem({ ...problem, title: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Problem Description"
            validateStatus={clientErrors?.description ? "error" : ""}
            help={clientErrors?.description}
            className="text-md font-bold"
          >
            <TextArea
              placeholder="Problem Description"
              size="large"
              rows={5}
              value={problem?.description}
              onChange={(e) =>
                setProblem({ ...problem, description: e.target.value })
              }
            />
          </Form.Item>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="md:w-[70%] w-full">
              <Form.Item
                label="Library"
                validateStatus={clientErrors?.library ? "error" : ""}
                help={clientErrors?.library}
                className="text-md font-bold"
              >
                <Select
                  size="large"
                  placeholder="Choose Library"
                  value={problem?.library || undefined}
                  onChange={(value) =>
                    setProblem({ ...problem, library: value })
                  }
                >
                  <OptGroup label="My Promptlets">
                    {stepsData?.myPromptlets?.map((item) => (
                      <Option key={item.value} value={item.value}>
                        <div className="flex justify-between px-2">
                          <span className="truncate">{item.label}</span>
                          <span className="text-[#8c8c8c] italic pl-2">
                            {item.author}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="Shared Promptlets">
                    {stepsData?.communityPromptlets?.map((item) => (
                      <Option key={item.value} value={item.value}>
                        <div className="flex justify-between px-2">
                          <span className="truncate">{item.label}</span>
                          <span className="text-[#8c8c8c] italic pl-2">
                            {item.author}
                          </span>
                        </div>
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </div>
            <div className="md:w-[28%] w-full">
              <Form.Item label="Language" className="text-md font-bold">
                <Select
                  size="large"
                  placeholder="Select Language"
                  value={problem?.language || undefined}
                  onChange={(value) =>
                    setProblem({ ...problem, language: value })
                  }
                >
                  {supportedLanguages?.map((language) => (
                    <Option key={language} value={language}>
                      {language}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddProblemModal;
