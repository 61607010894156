import React, { useState } from "react";
import { Modal, Upload, Button, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import handleCancelMergeDocument from "../../utils/problemConversation/Document/handleCancelMergeDocument";
import handleMergeDocument from "../../utils/problemConversation/Document/handleMergeDocument";

const { Dragger } = Upload;

const MergeDocumentModal = ({
  chats,
  isMergeDocumentModalVisible,
  setIsMergeDocumentModalVisible,
}) => {
  const [fileList, setFileList] = useState([]);
  const [documentFile, setDocumentFile] = useState(null);
  const { isLoading } = useSelector((state) => state.mergeChatsToDocument);
  const dispatch = useDispatch();
  const isMergeDocumentDisabled = isLoading || !documentFile;
  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
    setDocumentFile(fileList[0]?.originFileObj || null);
  };

  const beforeUpload = (file) => {
    const isDocx =
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    if (!isDocx) {
      message.error("You can only upload DOCX files!");
      return Upload.LIST_IGNORE;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("File must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <Modal
      title="Merge Document"
      open={isMergeDocumentModalVisible}
      onCancel={() =>
        handleCancelMergeDocument(
          setDocumentFile,
          setIsMergeDocumentModalVisible,
          dispatch
        )
      }
      footer={[
        <Button
          key="merge"
          className={`${
            !isMergeDocumentDisabled
              ? "!bg-gradient-to-r !from-blue-500 !to-blue-600 !text-white hover:!from-blue-600 hover:!to-blue-700 text-md font-bold"
              : ""
          }`}
          onClick={() => handleMergeDocument(documentFile, chats, dispatch)}
          disabled={isLoading || !documentFile}
          loading={isLoading}
        >
          Merge Document
        </Button>,
        <Button
          key="cancel"
          className="hover:!text-red-500 hover:!border-red-500"
          onClick={() =>
            handleCancelMergeDocument(
              setDocumentFile,
              setIsMergeDocumentModalVisible,
              dispatch
            )
          }
        >
          Cancel
        </Button>,
      ]}
    >
      <Dragger
        name="file"
        fileList={fileList}
        onChange={handleUploadChange}
        beforeUpload={beforeUpload}
        customRequest={({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
        multiple={false}
        accept=".docx"
      >
        <div className="flex flex-col">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag a <b>DOCX</b> file Template
          </p>
          <p className="ant-upload-hint">
            Support for a single upload. The file must be a <b>DOCX</b> and less
            than <b>5MB</b>.
          </p>
        </div>
      </Dragger>
    </Modal>
  );
};

export default MergeDocumentModal;
