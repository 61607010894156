export const customTheme = {
    base00: "#272822", // Background
    base08: "#f92672", // Red (Errors or special cases)
    base0B: "#00ff00", // Green (All JSON values)
    base0C: "#66d9ef", // Cyan (Optional fields, if needed)
    base0D: "#ae81ff", // Purple
    base0E: "#fd971f", // Orange
    base0F: "#cc6633", // Brown
    base01: "#383830", // Darker background
    base02: "#49483e", // Slightly darker
    base03: "#75715e", // Comments
    base04: "#a59f85", // Default text
    base05: "#f8f8f2", // Primary text
    base06: "#f5f4f1", // Light background
    base07: "#f9f8f5", // Lighter background
  };